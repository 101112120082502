@font-face {
  font-family: "Quicksand-Light";
  src: url("./media/fonts/Quicksand-Light.ttf");
}

@font-face {
  font-family: "Quicksand-Regular";
  src: url("./media/fonts/Quicksand-Regular.ttf");
}

@font-face {
  font-family: "Quicksand-Bold";
  src: url("./media/fonts/Quicksand-Bold.ttf");
}

:root {
  /* colors */
  --var-color-fed-green: #3ad7a2;
  --var-color-fed-navy: #0d5773;
  --var-color-fed-white: #ffffff;
  --var-color-fed-grey: #e6e6e6;
  /* font families */
  --var-quicksand-light: "Quicksand-Light";
  --var-quicksand-reg: "Quicksand-Regular";
  --var-quicksand-bold: "Quicksand-Bold";
  /* font sizes */
  --headline-font-size: 54px;
  --h1-font-size: 40px;
  --h2-font-size: 30px;
  --h3-font-size: 26px;
  --h4-font-size: 24px;
  --h5-font-size: 21px;
  --h6-font-size: 18px;
  --p-font-size: 18px;
  --p-font-weight: 100;
  --a-font-size: 18px;
  --btn-font-size: 14px;
  --menu-links-font-size: 16px;
}

*,
*::before,
*::after {
  margin: 0;
  font-family: var(--var-quicksand-reg);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  letter-spacing: normal;
  text-decoration: none;
}

*::-webkit-scrollbar {
  display: none;
}

body {
  position: relative;
  background-color: var(--var-color-fed-white);
  color: var(--var-color-fed-navy);
}

h1 {
  font-size: var(--h1-font-size);
}

@media (max-width: 1299px) {
  h1 {
    font-size: var(--h2-font-size);
  }
}

h2 {
  font-size: var(--h2-font-size);
}

h3 {
  font-size: var(--h3-font-size);
}

h4 {
  font-size: var(--h4-font-size);
}

@media (max-width: 1299px) {
  h4 {
    font-size: var(--h6-font-size);
  }
}

h5 {
  font-size: var(--h5-font-size);
}

h6 {
  font-size: var(--h6-font-size);
}

p {
  text-align: justify;
}

p,
li {
  font-size: var(--p-font-size);
  font-weight: var(--p-font-weight);
}

a {
  color: inherit;
  font-size: var(--a-font-size);
  font-family: var(--var-quicksand-bold);
}

ul,
ol {
  padding: 0;
}

li {
  list-style-type: none;
}

table {
  border-spacing: 0;
}

.container {
  margin: 0 auto;
  height: fit-content;
  z-index: 1000;
}

img {
  -webkit-user-drag: none;
  pointer-events: none;
  user-select: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

*::selection {
  -webkit-user-drag: none;
  color: var(--var-color-fed-white);
  background-color: var(--var-color-fed-green);
}

@media (max-width: 835px) {
  .container {
    margin: 0 40px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 740px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1199px) {
  .container {
    min-width: 1099px;
  }
}

@media (min-width: 1440px) {
  .container {
    min-width: 1240px;
  }
}

@media (min-width: 1680px) {
  .container {
    min-width: 1480px;
  }
}

@media (min-width: 1920px) {
  .container {
    min-width: 1720px;
  }
}

/* header styling */
#header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-image: linear-gradient(
    to bottom right,
    var(--var-color-fed-green) -100%,
    var(--var-color-fed-navy) 100%
  );
  z-index: 10000;
}

.header-content {
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo a {
  display: flex;
}

.logo img {
  height: 40px;
}

.main-menu {
  display: flex;
  height: 100%;
  align-items: center;
}

@media (max-width: 1299px) {
  .main-menu {
    display: none;
  }
}

.main-menu-item {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  border-bottom: 4px solid transparent;
  background-color: transparent;
  transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  -moz-transition: background-color 0.3s;
  -ms-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
}

.main-menu-item:hover {
  background-color: var(--var-color-fed-green);
  border-bottom-color: var(--var-color-fed-white);
}

.main-menu-item > a,
.main-menu-item > span {
  padding: 25px;
  font-family: var(--var-quicksand-bold);
  font-size: var(--menu-links-font-size);
  text-align: left;
  color: var(--var-color-fed-white);
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  transition: color 0.3s;
  -webkit-transition: color 0.3s;
  -moz-transition: color 0.3s;
  -ms-transition: color 0.3s;
  -o-transition: color 0.3s;
}

.main-menu-item:hover > a,
.main-menu-item:hover > span {
  color: var(--var-color-fed-navy);
}

.about-dropdown-menu {
  display: none;
}

.main-menu-item:hover > .about-dropdown-menu {
  position: absolute;
  left: 0;
  top: 82px;
  width: 200px;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.main-menu > .main-menu-item::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 100%;
  height: 10px;
}

.about-dropdown-menu .main-menu-item {
  border: none;
}

.about-dropdown-menu a {
  background-color: rgba(255, 255, 255, 85%);
  color: var(--var-color-fed-navy);
  padding: 10px 25px;
}

.about-dropdown-menu a:hover {
  background-image: linear-gradient(
    to bottom right,
    var(--var-color-fed-green) 0,
    var(--var-color-fed-navy) 100%
  );
  color: var(--var-color-fed-white);
}

/* mobile header */
.hamburger-menu {
  display: none;
  padding: 0;
  z-index: 100000;
}

@media (max-width: 1299px) {
  .hamburger-menu {
    display: flex;
  }
}

.hamburger-menu .buns {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3px;
}

.hamburger-menu span {
  background-color: var(--var-color-fed-white);
  width: 20px;
  height: 2px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.hamburger-menu:hover span {
  background-color: var(--var-color-fed-green);
}

.main-menu-2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  gap: 2px;
  background-color: var(--var-color-fed-navy);
  opacity: 95%;
  z-index: 10000000;
}

.main-menu-2 li {
  padding: 0 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  width: 100%;
  margin: 0 auto;
}

.main-menu-2 a {
  justify-content: center;
}

/* landing styling */
.landing {
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center center;
  display: flex;
  align-items: center;
  position: relative;
}

.overlay,
.film,
.light-film {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.film {
  background-color: var(--var-color-fed-navy);
}

.landing .overlay {
  z-index: 10;
}

.landing .film:nth-of-type(1) {
  background-color: var(--var-color-fed-navy);
  mix-blend-mode: color;
  opacity: 25%;
}

.landing .film:nth-of-type(2) {
  background-image: linear-gradient(
    to left,
    var(--var-color-fed-green) 0,
    var(--var-color-fed-navy) 100%
  );

  mix-blend-mode: multiply;
  opacity: 100%;
}

.landing-content {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.landing-content div {
  z-index: 10;
  width: calc((100% - 100px) / 2);
}

.landing-content h1 {
  font-family: var(--var-quicksand-bold);
  color: var(--var-color-fed-white);
  position: relative;
  text-transform: lowercase;
}

.landing-content h1::before {
  content: "";
  width: 100px;
  height: 4px;
  background-color: var(--var-color-fed-white);
  position: absolute;
  top: -10px;
  left: 0;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.landing-content h2 {
  font-family: var(--var-quicksand-light);
  font-size: var(--h3-font-size);
  color: var(--var-color-fed-green);
}

@media (max-width: 1299px) {
  .landing {
    overflow: hidden;
  }
  .landing-content div {
    width: 100%;
  }

  .landing .overlay {
    width: auto;
  }
}

/* breathing styling */
.breathing,
.block,
.testimonials,
.special-thanks,
.showcase,
.headline {
  padding: 100px 0;
}

@media (max-width: 1299px) {
  .breathing,
  .block,
  .testimonials,
  .special-thanks,
  .showcase,
  .headline {
    padding: 50px 0;
  }
}

.block {
  position: relative;
  background-size: cover;
  display: flex;
  flex-direction: column;
  min-height: 720px;
}

.breathing-content {
  display: flex;
  flex-direction: column;
  gap: 25px;
  justify-content: center;
  align-items: center;
}

.breathing p,
.btn {
  text-align: center;
  font-family: var(--var-quicksand-bold);
}

.btn {
  width: fit-content;
  padding: 10px 50px;
  background-image: linear-gradient(
    to right,
    var(--var-color-fed-navy) 0,
    var(--var-color-fed-green) 200%
  );
  color: var(--var-color-fed-white);
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  box-shadow: 0 0 15px rgba(255, 255, 255, 0);
  transition: box-shadow 0.5s, color 0.5s;
  -webkit-transition: box-shadow 0.5s, color 0.5s;
  -moz-transition: box-shadow 0.5s, color 0.5s;
  -ms-transition: box-shadow 0.5s, color 0.5s;
  -o-transition: box-shadow 0.5s, color 0.5s;
  z-index: 1000;
}

.btn:hover {
  box-shadow: 0 0 15px rgba(58, 215, 162, 50%);
  font-family: var(--var-quicksand-bold);
  color: var(--var-color-fed-green);
  text-decoration: underline;
}

.block a:hover {
  color: var(--var-color-fed-green);
  text-decoration: underline;
}

/* block & section styling */
.block-content {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

@media (max-width: 1299px) {
  .block-content {
    gap: 20px;
  }
}

video {
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  z-index: 1000;
}

.section {
  display: flex;
  gap: 100px;
  justify-content: flex-end;
}

.section-title,
.section-description,
.section-children,
.section-items,
.item-text {
  display: flex;
  flex-direction: column;
}

.section-title {
  position: relative;
  width: 30%;
}

@media (max-width: 1299px) {
  .section-title {
    width: 100%;
  }
}

.section-subheading,
.section-heading {
  font-family: var(--var-quicksand-bold);
  position: relative;
  text-transform: lowercase;
}

.section-subheading {
  color: var(--var-color-fed-green);
}

.bar {
  width: 100px;
  height: 3px;
  position: absolute;
  top: -10px;
  left: 0;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.section-description {
  width: 70%;
  gap: 25px;
  z-index: 10;
}

.section-divider:nth-last-child(2) {
  height: 20px;
}

@media (max-width: 1299px) {
  .section {
    flex-direction: column;
    gap: 25px;
  }

  .section > * {
    width: 100%;
  }

  .section .btn,
  .breathing .btn {
    align-self: center;
    font-size: var(--btn-font-size);
  }

  .section-divider:nth-last-child(2) {
    height: 0;
  }
}

.section-children {
  gap: 25px;
}

.section em {
  color: var(--var-color-fed-green);
  font-family: var(--var-quicksand-bold);
}

.section em:last-of-type {
  text-align: right;
}

.block .film:nth-child(1) {
  background-color: var(--var-color-fed-navy);
  mix-blend-mode: multiply;
  opacity: 60%;
}

.block .film:nth-child(2) {
  background-image: linear-gradient(
    to top left,
    var(--var-color-fed-green) 0,
    var(--var-color-fed-navy) 100%
  );

  mix-blend-mode: multiply;
  opacity: 60%;
}

.block .light-film:nth-child(1) {
  background-color: var(--var-color-fed-navy);
  mix-blend-mode: color;
  opacity: 25%;
}

.block .light-film:nth-child(2) {
  background-image: linear-gradient(
    to top,
    transparent 0,
    var(--var-color-fed-white) 100%
  );
}

.block .light-film:nth-child(3) {
  background-image: linear-gradient(
    to bottom left,
    transparent 0,
    var(--var-color-fed-green) 300%
  );
  mix-blend-mode: multiply;
  opacity: 100%;
}

.block .book {
  width: 300px;
}

.block .book:first-of-type {
  margin-bottom: 50px;
  transform: rotateY(0deg);
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
}

.block .book:last-of-type {
  margin-top: 50px;
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  align-self: flex-end;
}

.section-items {
  gap: 50px;
}

.h-section-items {
  flex-direction: row;
}

@media (max-width: 1299px) {
  .h-section-items {
    flex-direction: column;
  }
}

.section-item {
  display: flex;
  gap: 25px;
  align-items: flex-start;
  position: relative;
}

.section-item .item-icon {
  width: 60px;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
}

.item-text {
  gap: 5px;
  position: relative;
}

.item-text .bar {
  height: 3px;
  width: 100px;
  background-color: var(--var-color-fed-green);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.item-text h6 {
  font-family: var(--var-quicksand-bold);
  color: var(--var-color-fed-green);
}

.item-text p {
  color: var(--var-color-fed-white);
}

.driven-services,
.service-col {
  display: flex;
}

.service-col {
  width: 25%;
  height: 500px;
  background-size: cover;
  background-position: center center;
  position: relative;
  justify-content: flex-start;
  padding: 40px;
  overflow: hidden;
  padding-top: 320px;
}

.service-col:first-child {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.service-col:last-child {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

@media (max-width: 1299px) {
  .driven-services {
    flex-direction: column;
  }

  .service-col {
    width: 100%;
  }

  .service-col:first-child {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 0;
  }

  .service-col:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}

.service-col .overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to bottom right,
    var(--var-color-fed-green) 0,
    var(--var-color-fed-navy) 100%
  );
  mix-blend-mode: multiply;
  opacity: 75%;
}

.service-col:hover .overlay {
  background-image: linear-gradient(
    to bottom right,
    var(--var-color-fed-navy) 0,
    var(--var-color-fed-navy) 100%
  );
  opacity: 100%;
}

.service-col div {
  position: relative;
  color: var(--var-color-fed-white);
}

.service-col .bar {
  background-color: var(--var-color-fed-white);
}

/* testimonials styling */
.testimonials,
.testimonials-slider {
  display: flex;
  gap: 25px;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.testimonials-slider {
  mask-image: linear-gradient(
    to right,
    transparent 0%,
    var(--var-color-fed-white) 25.1%,
    var(--var-color-fed-white) 74.9%,
    transparent 100%
  );
}

@media (max-width: 1299px) {
  .testimonials-slider {
    flex-direction: column;
  }

  .testimonials,
  .testimonials-slider {
    justify-content: center;
  }
}

@media (min-width: 1299px) {
  .testimonials-slider {
    width: 100%;
  }
}

.testimonials .arrow {
  width: fit-content;
  height: fit-content;
  width: 40px;
  height: 40px;
  background-size: cover;
  background-position: center center;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  background-color: var(--var-color-fed-green);
  display: flex;
  justify-content: center;
  align-items: center;
}

.testimonials .arrow:first-of-type {
  left: -70px;
}

.testimonials .arrow:last-of-type {
  right: -70px;
}

@media (max-width: 1299px) {
  .testimonials .arrow:first-of-type {
    left: -20px;
  }

  .testimonials .arrow:last-of-type {
    right: -20px;
  }
}

.testimonials .arrow span {
  width: 10px;
  height: 10px;
  border: 2px solid;
  border-color: transparent transparent var(--var-color-fed-navy)
    var(--var-color-fed-navy);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-30%, -50%, 0) rotateZ(45deg);
  -webkit-transform: translate3d(-30%, -50%, 0) rotateZ(45deg);
  -moz-transform: translate3d(-30%, -50%, 0) rotateZ(45deg);
  -ms-transform: translate3d(-30%, -50%, 0) rotateZ(45deg);
  -o-transform: translate3d(-30%, -50%, 0) rotateZ(45deg);
  transition: border-color 0.3s;
  -webkit-transition: border-color 0.3s;
  -moz-transition: border-color 0.3s;
  -ms-transition: border-color 0.3s;
  -o-transition: border-color 0.3s;
}

.testimonials .arrow:hover span {
  border-color: transparent transparent var(--var-color-fed-white)
    var(--var-color-fed-white);
}

.testimonials .arrow:last-of-type span {
  transform: translate3d(-70%, -50%, 0) rotateZ(225deg);
  -webkit-transform: translate3d(-70%, -50%, 0) rotateZ(225deg);
  -moz-transform: translate3d(-70%, -50%, 0) rotateZ(225deg);
  -ms-transform: translate3d(-70%, -50%, 0) rotateZ(225deg);
  -o-transform: translate3d(-70%, -50%, 0) rotateZ(225deg);
}

.testimonials .arrow:hover {
  background-color: transparent;
  background-image: linear-gradient(
    to bottom right,
    var(--var-color-fed-green) 0,
    var(--var-color-fed-navy) 100%
  );
}

.testimonial,
.valued-partner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 50px 50px;
  background-color: var(--var-color-fed-grey);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  width: calc(100% / 3);
  height: fit-content;
  flex-grow: 0;
  transition: width 0.5s, flex-grow 0.5s, padding 0.5s;
  -webkit-transition: width 0.5s, flex-grow 0.5s, padding 0.5s;
  -moz-transition: width 0.5s, flex-grow 0.5s, padding 0.5s;
  -ms-transition: width 0.5s, flex-grow 0.5s, padding 0.5s;
  -o-transition: width 0.5s, flex-grow 0.5s, padding 0.5s;
}

.testimonial h2 {
  font-family: var(--var-quicksand-bold);
}

.testimonial h2,
.testimonial h5,
.testimonial p {
  transition: color 0.5s;
  -webkit-transition: color 0.5s;
  -moz-transition: color 0.5s;
  -ms-transition: color 0.5s;
  -o-transition: color 0.5s;
}

.testimonial:nth-of-type(2) {
  background-image: linear-gradient(
    to top right,
    var(--var-color-fed-navy) 0,
    var(--var-color-fed-green) 150%
  );
  flex-grow: 1;
  width: calc((100% / 3) + 100px);
  padding: 100px 50px;
}

.testimonial:nth-of-type(2) h2,
.testimonial:nth-of-type(2) h5,
.testimonial:nth-of-type(2) p {
  color: var(--var-color-fed-white);
}

.testimonial p {
  text-align: center;
}

.testimonial img {
  width: 50px;
  padding-top: 25px;
}

/* footer styling */
.footer {
  padding-top: 50px;
  padding-bottom: 10px;
  background-image: linear-gradient(
    to top right,
    var(--var-color-fed-green) -200%,
    var(--var-color-fed-navy) 100%
  );
  color: var(--var-color-fed-white);
}

#header + .footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}

.identity {
  display: flex;
  padding-bottom: 20px;
  gap: 50px;
  justify-content: flex-end;
}

.identity .about {
  justify-self: flex-start;
  flex-grow: 1;
}

.identity .about > p {
  text-align: left;
  max-width: 50%;
}

.footer-section,
.about {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: flex-start;
}

.footer-section {
  width: calc(50% / 3);
}

@media (max-width: 1299px) {
  .footer-section {
    width: 100%;
  }

  .identity .about > p {
    max-width: 100%;
  }
}

.footer-section h4 {
  font-size: var(--h6-font-size);
}

.footer-section h6 {
  color: var(--var-color-fed-green);
}

.footer-section a {
  font-family: var(--var-quicksand-reg);
  color: var(--var-color-fed-white);
  transition: color 0.5s;
  -webkit-transition: color 0.5s;
  -moz-transition: color 0.5s;
  -ms-transition: color 0.5s;
  -o-transition: color 0.5s;
}
.footer-section a:hover {
  font-family: var(--var-quicksand-bold);
  color: var(--var-color-fed-green);
}

.copyright,
.powered {
  display: flex;
  align-items: center;
}

.powered {
  gap: 5px;
  align-items: center;
}

.powered p {
  color: var(--var-color-fed-white);
  font-family: var(--var-quicksand-bold);
}

.powered span:nth-of-type(2) {
  color: #fe7764;
  font-family: var(--var-quicksand-bold);
}

.copyright {
  height: 50px;
  border-top: 1px solid var(--var-color-fed-white);
  border-radius: 2px;
  justify-content: space-between;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
}

.identity .item-text .bar {
  background-color: var(--var-color-fed-white);
}

.identity .item-text h6 {
  color: var(--var-color-fed-white);
  font-size: var(--h4-font-size);
}

.identity .item-text p {
  color: var(--var-color-fed-green);
}

@media (max-width: 1299px) {
  .identity,
  .copyright {
    flex-direction: column;
  }

  .copyright {
    padding: 10px 0;
    height: fit-content;
    gap: 10px;
  }
}

.social {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.social-icons {
  display: flex;
  gap: 5px;
}

.social-icons a {
  width: 30px;
  height: 30px;
}

.social-icons a img {
  width: 100%;
  height: 100%;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
}

.footer p,
.footer a,
.footer h6,
.footer span {
  font-size: small;
}

/**/
.give-back {
  position: absolute;
  left: 0;
  width: 90%;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

@media (max-width: 1299px) {
  .give-back-book {
    display: none;
  }

  .give-back-book + .section-divider + .section .section-children {
    display: none;
  }
}

/* special thanks styling */
.special-thanks {
  background-color: var(--var-color-fed-white);
}

.valued-partner {
  display: flex;
  flex-direction: column;
  padding: 0;
  gap: 0;
  position: relative;
  cursor: pointer;
}

.valued-partner img {
  border: 1px solid var(--var-color-fed-grey);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 100%;
}

.valued-partner h4 {
  padding: 40px 20px;
  text-align: center;
  width: 100%;
  font-size: var(--h6-font-size);
}

.active {
  flex-grow: 1;
  /* width: calc((100% / 3) + 130px); */
}

.small {
  width: 10%;
}

.medium {
  width: 25%;
}

.active h4 {
  color: var(--var-color-fed-white);
  font-size: var(--h4-font-size);
  font-family: var(--var-quicksand-bold);
  background-image: linear-gradient(
    to top right,
    var(--var-color-fed-navy) 0,
    var(--var-color-fed-green) 150%
  );
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

@media (max-width: 1299px) {
  .valued-partner {
    display: none;
  }

  .active {
    display: flex;
    width: 100%;
  }

  .active h4 {
    font-size: var(--btn-font-size);
    padding: 20px;
  }
}

/* our services */
.showcase {
  display: flex;
  flex-direction: column;
  gap: 100px;
  align-items: center;
}

.product {
  display: flex;
  gap: 50px;
  justify-content: center;
  align-content: center;
  align-items: flex-start;
  width: 90%;
}

.product img {
  width: 25%;
}

.product .product-text {
  width: 75%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.product-text h2 {
  color: var(--var-color-fed-green);
  font-family: var(--var-quicksand-bold);
  font-size: var(--h3-font-size);
}

.product-text h3 {
  font-size: var(--h4-font-size);
}

.product-text p {
  margin-top: 50px;
  text-align: justify;
}

@media (max-width: 1299px) {
  .product {
    flex-direction: column;
  }

  .product img,
  .product .product-text {
    width: 100%;
  }
}

.job-link {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.headline-content {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/* portfolio */
.case-study {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  gap: 20px;
  padding: 50px;
  background-color: var(--var-color-fed-navy);
  color: var(--var-color-fed-white);
  background-size: cover;
  background-position: center center;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  height: 640px;
  position: relative;
  overflow: hidden;
}

.case-study * {
  text-align: center;
  z-index: 100;
}

.case-study img {
  position: absolute;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  width: 200%;
  mix-blend-mode: multiply;
  opacity: 50%;
  z-index: 99;
}

@media (max-width: 1299px) {
  .case-study {
    height: fit-content;
  }
}

.contact-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 27px;
}

.contact-form input,
.input,
textarea,
select {
  position: relative;
  width: 100%;
  background-color: transparent;
}

.contact-form input,
textarea,
select {
  border: 2px solid var(--var-color-fed-navy);
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  padding: 10px 20px;
}

select {
  appearance: none;
}

.select::after {
  content: "";
  position: absolute;
  right: 25px;
  top: 45px;
  transform: rotateZ(45deg);
  -webkit-transform: rotateZ(45deg);
  -moz-transform: rotateZ(45deg);
  -ms-transform: rotateZ(45deg);
  -o-transform: rotateZ(45deg);
  width: 10px;
  height: 10px;
  border: 2px solid;
  border-color: transparent var(--var-color-fed-navy) var(--var-color-fed-navy)
    transparent;
}

textarea {
  height: 140px;
}

.input .bar {
  background-color: var(--var-color-fed-green);
}

.input-title {
  margin-bottom: 10px;
  font-family: var(--var-quicksand-bold);
  color: var(--var-color-fed-green);
}

/* loader & message styling */
.loading {
  margin: 0 auto;
}

.loading img {
  position: fixed;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  margin: 0 auto;
  width: 100px;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  z-index: 5000;
  mix-blend-mode: multiply;
}

.message {
  width: 100%;
  position: fixed;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
}

.message .message-body {
  background-color: red;
  color: var(--var-color-fed-white);
  padding: 10px 20px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  text-align: justify;
}

@media (max-width: 1299px) {
  .card {
    flex-direction: column;
  }
}
